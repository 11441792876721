body {
  margin: 0;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4444;
  background-color: #f6f5ef;
}

h1,
p {
  letter-spacing: 1px;
}

h2 {
  letter-spacing: 6px;
}

h5 {
  font-weight: lighter;
}

i {
  font-weight: normal;
}

a {
  text-decoration: none;
  color: #4a4444;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 20px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  h1 {
    font-size: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  h1 {
    font-size: 40px;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 50px;
  }
}
